import axios from 'axios'
import {
    Message,
    Loading
} from 'element-ui';
import router from './router'
axios.defaults.withCredentials = true; //设置cross跨域 并设置访问权限 允许跨域携带cookie信息
var domain = process.env.NODE_ENV === 'production' ? '' : 'https://ordermall.zhimaiyun.cn';
// var domain = process.env.NODE_ENV === 'production' ? '' : 'http://tyyg.com'
//创建axios实例
var service = axios.create({
    baseURL: domain,
    headers: {
        'content-type': 'application/json',
    }
})
export default {
    domain: domain,
    //post请求，其他类型请求复制粘贴，修改method
    post(url, param, load = true) {
        let loadingInstance;
        if (load) {
            loadingInstance = Loading.service({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
                customClass: 'myLoading',
            });
        }
        return new Promise((cback, reject) => {
            service({
                method: 'post',
                url,
                data: JSON.stringify(param)
            }).then(res => {
                if (load) {
                    loadingInstance.close();
                }
                //axios返回的是一个promise对象
                var res_code = res.data.code.toString();
                if (res_code == 200) {
                    cback(res.data.data); //cback在promise执行器内部
                } else if (res_code == 201) {
                    localStorage.removeItem('houseInfo');
                    Message({
                        showClose: true,
                        message: res.data.message,
                        type: 'error'
                    });
                    router.push('/');
                }  else if (res_code == 2001) {
                    reject(res.data)
                } else {
                    reject(false)
                    Message({
                        showClose: true,
                        message: res.data.message,
                        type: 'error'
                    });
                }
            }).catch(err => {
                if (load) {
                    loadingInstance.close();
                }
                if (!err.response) {
                    console.log('请求错误')
                    Message({
                        showClose: true,
                        message: '请求错误',
                        type: 'error'
                    });
                } else {
                    reject(err.response);
                    console.log(err.response, '异常')
                }
            })
        })
    },
}